<template>
    <div class="col-md-12">
        <div class="modal fade modal_cust show" v-if="modalId=='newContact'|| modalId=='editContactDetails'" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle1" style="display: block;" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal_ac_head single-column-popup" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalCenterTitle">{{(modalId=='newContact')?'Add Contact Person':'Edit Contact Person'}}</h5>
                        <a class="close"  @click="$store.dispatch('modalClose')" title="Close">
                            <span aria-hidden="true">×</span>
                        </a>
                    </div>
                    <form @submit.prevent="addContactPerson" class="bg-theam-secondary">
                        <div class="modal-body row">
                            <div class="col-md-12 pd-r-7-5">
                                <div class="group">
                                    <label class="group-label">Detail</label>
                                    <div class="group-attribute-container">
                                        <div class="row mb-2">
                                            <div class="col-md-5 mr-0 pr-0">Name *</div>
                                            <div class="col-md-7 ml-0 pl-0">
                                                <input type="text" v-model="formData.name" placeholder="Name" class="form-control" required>
                                            </div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col-md-5 mr-0 pr-0">Designation </div>
                                            <div class="col-md-7 ml-0 pl-0">
                                                <input type="text" v-model="formData.designation" placeholder="CEO" class="form-control" >
                                            </div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col-md-5 mr-0 pr-0">Phone No*</div>
                                            <div class="col-md-7 ml-0 pl-0">
                                                <input type="tel" v-model="formData.phone_no" placeholder="98xxxxxxx" class="form-control" required>

                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-5 mr-0 pr-0">Email </div>
                                            <div class="col-md-7 ml-0 pl-0">
                                                <input type="email" v-model="formData.email" placeholder="abc@domain.com" class="form-control">

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 text-right mt-3">
                                <button type="submit" class="btn btn-sm btn-primary" :disabled="disableSubmitButton">{{ disableSubmitButton ? 'Submitting....' : 'Submit'}}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import Services from "./Services/Services";
// import Services from '../Supplier/Services/Services';
export default {
    data(){
        return{
            formData:{ 
              'designation':'',
              'name':'',
              'phone_no':'',
              'email':''
            },
            disableSubmitButton:false,
        }
    },
  computed: {
      ...mapGetters(["eventMessage","dataLists","dataLists2","dataLists8","modalId","dataId","modalState"]),
      ...mapGetters("customers",["customerDatas"]),
      },
  mounted() {
      this.formData.catagory = this.dataLists8.account_type;
      this.formData.company=this.$route.params.id;
  },
  methods:{
      addContactPerson(){
        this.disableSubmitButton= true;
        if(this.modalId == 'newContact'){
            Services.createContactPerson(this.formData).then(res=>{
                this.disableSubmitButton = false;
                this.$store.dispatch("modalClose");
                this.setNotification(res.data.success_message);
            }).catch(err=>{
                console.log(err);
                this.disableSubmitButton =false;
                if(err.response.status == 422){
                    let error = Object.values(err.response.data.errors).flat();
                    this.setNotification(error);
                }else{
                    console.log(err);
                }
            });
        }
        if(this.modalId == 'editContactDetails'){
            Services.updateContactPerson(this.formData,this.formData.id).then(response=>{
            Services.getCustomerData(this.$route.params.id).then(res=>{
                this.$store.commit("customers/setCustomerDatas",res.data.data.customer);
                this.$store.commit("setDataLists8",res.data.data);
                this.$store.dispatch("modalClose");
                this.disableSubmitButton = false;
                this.setNotification(response.data.success_message);
            }).catch(err=>{
                console.log(err);
            });
            }).catch(err=>{
                console.log(err);
                this.disableSubmitButton =false;
                if(err.response.status == 422){
                    let error = Object.values(err.response.data.errors).flat();
                    this.setNotification(error);
                }else{
                    console.log(err);
                }
            });
        }
        
        },
         setNotification(message){
        this.$store.dispatch("enableMessage");
        this.$store.commit("setEventMessage",message);
    },
    },
    watch:{
        // eventMessage(value){
        //     if(value.indexOf("success") >= 0){
        //         this.$store.dispatch('modalClose');
        //     }
        // },
        modalId(){
            if(this.modalId == 'newContact'){
                this.formData.designation = '';
                this.formData.name = '';
                this.formData.phone_no ='';
                this.formData.email = '';
                this.formData.catagory = this.dataLists8.account_type;
                this.formData.company=this.$route.params.id;
            }
            if(this.modalId == 'editContactDetails'){
                let id = this.dataId;
                let details = this.dataLists8.contact_details.find(function (data) {
                    return data.id == id;
                });
                this.formData = {...details};
            }
        }
    }
}
</script>