<template>
  <div class="tab-pane fade" id="receipts" role="tabpanel" aria-labelledby="contact-tab5">
    <div class="bg-theam-secondary table-search-head no-print pt-0">
      <div class="row">
        <div class="filter-input col-md-9 d-block mr-0 pr-0">
          <select name="size" @change="receiptFilter()" v-model="receiptParams.size" class="mr-2 mg-t-5">
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="500">500</option>
            <option value="All">All</option>
          </select>
          <div class="mr-2 d-inline-block">
            <label class="filter-lavel mb-0 mr-1 mg-t-5">From</label>
            <input
              type="date"
              v-model="receiptParams.from"
              placeholder="From"
              class="filter-input mr-1 mg-t-5"
              @change="receiptFilter()"
            />
            <label class="filter-lavel mb-0 mr-1 mg-t-5">To</label>
            <input
              type="date"
              v-model="receiptParams.to"
              placeholder="To"
              class="filter-input mr-1 mg-t-5"
              @change="receiptFilter()"
            />
          </div>
        </div>
        <div class="col-md-3 mg-t-5">
          <div class="text-right">
            <button class="btn-icons" @click="print()">
              <i class="fas fa-print pr-1"></i>
            </button>
            <!-- <button class="btn-icons">
              <i class="fas fa-file-pdf pr-1"></i>
            </button>
            <button class="btn-icons">
              <i class="fas fa-file-excel pr-1"></i>
            </button>
            <button class="btn-icons">
              <i class="fas fa-download"></i>
            </button>-->
          </div>
        </div>
      </div>
    </div>
    <div class="overflow-x-auto">
      <table class="table table2 table-hover">
        <thead>
          <tr>
            <th class="wd-5px table-start-item">S.N.</th>
            <th class="wd-20p">Date</th>
            <th class="wd-20p">Receipt ID</th>
            <th class="wd-25p">Payment Mode</th>
            <th class="wd-20p">Paid Amount</th>
            <th class="wd-10px text-center table-end-item" v-if="checkSinglePermission('view_pay_in')">Action</th>
          </tr>
        </thead>
        <tbody v-if="!receiptParams.loading && receiptDatas.length > 0">
          <tr v-for="(receipts, index) in receiptDatas" :key="index">
            <td class="table-start-item">{{ pageSerialNo+index }}.</td>
            <td>{{ receipts.date }}</td>
            <td>
              {{ receipts.receipt_no }}
              <span class="tx-danger" v-if="receipts.status == 1">-Canceled</span>
            </td>
            <td>
              {{ receipts.payment_methods.title }}
              <span class="tx-primary">&nbsp;{{(receipts.type == 1)?' -Advance':''}}</span>
            </td>
            <td>{{ parseDigitForSlip(receipts.paid_amount) }}</td>
            <td class="text-right table-end-item" v-if="checkSinglePermission('view_pay_in')">
              <a href="javascript:;" class="mr-3" title="View Slip">
                <i class="fas fa-eye" @click="view('receiptSlip', receipts.id)"></i>
              </a>
            </td>
          </tr>
        </tbody>
        <tbody v-else-if="!receiptParams.loading && receiptDatas.length == 0">
          <tr>
            <td colspan="11" style="text-align:center;">No records found.</td>
          </tr>
        </tbody>
        <tbody v-else-if="receiptParams.loading">
          <tr>
            <td colspan="11" style="text-align:center;">Loading.....</td>
          </tr>
        </tbody>
        <tbody v-else-if="receiptParams.error"><tr><td colspan="11" class="text-center">{{receiptParams.error_message}}</td></tr></tbody>
      </table>
    </div>
    <ReceiptSlip />
    <Paginate
      :pageCount="receiptParams.totalPageCount"
      :containerClass="'pagination'"
      :clickHandler="clickCallback"
      v-if="receiptParams.totalPageCount > 1"
    />
  </div>

  <!-- content -->
</template>
<script>
import { mapGetters } from "vuex";
import ReceiptSlip from "../Customer/PaymentSlip";
import Paginate from "vuejs-paginate";
// import EditPayIn from "../Customer/Edit-pay-in";
import Services from "./Services/Services";
export default {
  data() {
    return {
      pageSerialNo: 1,
    };
  },
  computed: {
    ...mapGetters([
      "icons",
      "dataId",
      "dataLists",
      "dataLists1",
      "dataLists2",
      "dataLists3",
      "eventMessage",
      "modalId",
    ]),
    ...mapGetters("customers", ["receiptDatas", "receiptParams"]),
  },
  components: {
    ReceiptSlip,
    Paginate,
  },
  methods: {
    print() {
      window.print();
    },
    clickCallback: function (page) {
      if (page == 1) {
        this.pageSerialNo = 1;
      } else {
        this.pageSerialNo = (page - 1) * this.receiptParams.size + 1;
      }
      this.receiptParams.offset = (page - 1) * this.receiptParams.size;
      this.receiptFilter();
    },
    view(modal, id) {
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalWithMode", { id: modal, mode: "read" });
    },
    receiptFilter() {
      this.receiptParams.loading = true;
      Services.getReceiptFilteredDatas(this.receiptParams)
        .then((res) => {
          this.$store.commit("customers/setReceiptDatas", res.data.data.data);
          this.receiptParams.totalPageCount = res.data.data.total_no_of_pages;
          this.receiptParams.loading = false;
          this.setNotification(res.data.success_message);
        })
        .catch((err) => {
          this.receiptParams.error = true;
          this.receiptParams.loading = false;
          let error = err.response.data.error_message;
          this.setNotification(error);
          if (
            !Object.prototype.hasOwnProperty.call(
              err,
              "response.data.error_message"
            )
          ) {
            if (err.response.data.error_message == "Access Denied.") {
              this.receiptParams.error_message = "Access Denied !!!";
            } else {
              this.receiptParams.error_message = "Error fetching data from the server.";
            }
          }
        });
    },
    setNotification(message) {
      this.$store.dispatch("enableMessage");
      this.$store.commit("setEventMessage", message);
    },
  },
};
</script>
<style scoped>
</style>