<template>
  <div class="col-md-12">
    <div
      class="modal fade modal_cust show"
      v-if="modalId == 'receiptSlip' || modalId =='payoutSlip'"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle1"
      style="display: block"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal_ac_head" role="document">
        <div class="modal-content bg-white border0">
          <div class="cancel-watermark" v-if="(details.status == 1)">
            <div class="water-mark-content">Cancelled</div>
          </div>
          <div class="modal-header no-print">
            <div class="row">
              <div class="col-6">
                <h5
                  class="modal-title"
                  id="exampleModalCenterTitle"
                >Payment Slip - #{{details.receipt_no}}</h5>
              </div>
              <div class="col-6 d-flex justify-content-end">
                <div class="bill-head-icon mr-3">
                  <i class="fas fa-print" title="Print Slip" @click="printReceipt"></i>
                </div>
                <div class="bill-head-icon mr-3">
                  <i class="fas fa-download" title="Download Slip"></i>
                </div>
                <div
                  class="bill-head-icon mr-1"
                  @click="$store.dispatch('modalClose', 'recieptSlip')"
                >
                  <i class="fas fa-times" title="Close Slip"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="p-2 bill-container">
            <div class="text-center">
              <p class="font-xxxl mb-0">{{authData.org_detail.name}}</p>
              <p class="mb-0">
                {{details.branchs.address}}
                <br />
                Tel No:{{authData.org_detail.phone}} / {{authData.org_detail.email}}
                <br />
                PAN:{{authData.org_detail.pan_vat}}
              </p>
              <hr class="print-hr-margin" />
            </div>
            <!-- col -->
            <div class="row">
              <div class="col-sm-4 col-lg-4 mg-t-10 mg-t-sm-40 order-3 order-sm-0">
                <label class="content-label mb-1">Customer</label>
                <!-- <h6 class="tx-15 mg-b-10">Juan Dela Cruz</h6>  -->
                <p class="mg-b-0">NAME : {{ details.customer.name }}</p>
                <p class="mg-b-0">ADDRESS : {{details.customer.address}}</p>
              </div>
              <!-- col -->
              <div class="col-sm-4 col-lg-4 order-1 order-sm-0">
                <p class="text-center font-l m-0">{{(modalId=="payoutSlip")?'Payemnt Voucher':'Receipt Voucher'}}</p>
                <hr class="print-sm-hr print-hr-margin" />
              </div>
              <!--- col -->
              <div class="col-sm-4 col-lg-4 mg-t-sm-15 mg-t-10 order-2 order-sm-0">
                <p class="mg-b-0 text-sm-right">Receipt No. : {{ details.receipt_no }}</p>
                <p class="mg-b-0 text-sm-right">DATE : {{ details.date }}</p>
              </div>
              <!-- col -->
            </div>
            <!-- row -->

            <div class="row justify-content-between mt-2 font-s font-w-400">
              <div class="col-sm-12 col-lg-12 order-2 order-sm-0 mg-t-0 mg-sm-t-0">
                <div class="table-responsive">
                  <table class="table table-bordered mg-b-0 table_lr_border color-secondary">
                    <thead>
                      <tr>
                        <th scope="col" class="border-right_1">Payment Type</th>
                        <th scope="col" class="border-right_1">Payment ID</th>
                        <th scope="col">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {{ details.payment_methods.title}}
                          <span v-if="details.bank!=null">-{{details.banks.bank}}</span>
                        </td>
                        <td>{{ details.transaction_id }}</td>
                        <td>{{ parseDigitForSlip(details.paid_amount) }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <!-- col -->
            </div>
            <div class="row justify-content-between m-0 p-0 mt-0 mt-sm-1 font-s font-w-400">
              <div
                class="col-sm-6 col-lg-6 order-2 m-0 p-0 order-sm-0 mg-t-5 mg-t-sm-40 mg-sm-t-0 pr-sm-1"
              >
                <!-- Shipping-detail -->
                <div class="footer_detail mt-1 mb-0 p-2 ht-95p">
                  <label class="content-label mb-1">Payee Detail</label>
                  <p class="mg-b-0">Payee Name : {{ details.payee_name }}</p>
                  <p class="mg-b-0">Contact No : {{ details.phone_no }}</p>
                </div>
                <!-- Shipping-detail -->
              </div>
              <!-- col -->
              <div
                class="col-sm-6 col-lg-6 order-1 order-sm-0 m-0 p-0 mg-t-10 mg-t-sm-40 mg-sm-t-0 pl-sm-1"
              >
                <!-- Note -->
                <div class="footer_detail mt-1 mb-0 p-2 ht-95p">
                  <label class="content-label mb-1">Amount in Word</label>
                  <p class="mb-0">{{ toWord(details.paid_amount) }} only</p>
                </div>
                <!-- Note -->
              </div>
              <!-- col -->
            </div>
            <hr />
            <div class="footer_note">
              <label class="content-label mb-1 no-print-sm">Note</label>
              <p class="mb-2 text-center text-sm-left">{{ details.description }}</p>
            </div>
            <p class="text-right">User: {{ details.user_details.name }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      details: [],
    };
  },
  computed: {
    ...mapGetters(["dataLists","dataLists3","authData", "dataId", "modalId", "modalMode"]),
    ...mapGetters("customers",["receiptDatas","payOutDatas"])
  },
  methods:{
    printReceipt(){
      window.print;
    }
  },
  watch: {
    modalId(){
      let id= this.dataId;
      if(this.modalId == "receiptSlip"){
          this.details = this.receiptDatas.find(function (data) {
            return data.id == id;
          });
      }
      if(this.modalId == 'payoutSlip'){
        this.details = this.payOutDatas.find(function (data) {
        return data.id == id;
      });
      }
    }
  },
};
</script>