<template>
  <div class="tab-pane fade" id="ledger" role="tabpanel" aria-labelledby="contact-tab5">
    <div class="bg-theam-secondary table-search-head no-print pt-0">
      <div class="row">
        <div class="filter-input col-md-9 d-block mr-0 pr-0">
          <!-- <div class="d-inline-block">
            <label class="filter-lavel mb-0 mr-1 mg-t-5">From</label>
            <input
              type="date"
              v-model="ledgerParams.from"
              placeholder="From"
              class="filter-input mr-1 mg-t-5"
              @change="ledgerFilter"
            />
            <label class="filter-lavel mb-0 mr-1 mg-t-5">To</label>
            <input
              type="date"
              v-model="ledgerParams.to"
              placeholder="To"
              class="filter-input mr-1 mg-t-5"
              @change="ledgerFilter"
            />
          </div> -->
        </div>
        <div class="col-md-3 mg-t-5">
          <div class="text-right">
            <button class="btn-icons">
              <i class="fas fa-print pr-1" @click="print()"></i>
            </button>
            <!-- <button class="btn-icons">
              <i class="fas fa-file-pdf pr-1"></i>
            </button>
            <button class="btn-icons">
              <i class="fas fa-file-excel pr-1"></i>
            </button>
            <button class="btn-icons">
              <i class="fas fa-download"></i>
            </button>-->
          </div>
        </div>
      </div>
    </div>
    <div class="overflow-x-auto">
      <table class="table table2 table-hover" v-if="ledgerDatas.data">
        <thead>
          <tr>
            <th class="wd-15p table-start-item">Date</th>
            <th class="wd-35p">Description</th>
            <th class="wd-15p text-right table-end-item">Dr. Amount</th>
            <th class="wd-15p text-right table-end-item">Cr. Amount</th>
            <th class="wd-20p text-right table-end-item">Balance</th>
          </tr>
        </thead>
        <tbody v-if="!ledgerParams.loading && ledgerDatas.data.length > 0">
          <tr v-for="(ledger, index) in ledgerDatas.data" :key="index">
            <th scope="row" class="table-start-item">{{ ledger.date }}</th>
            <td>{{ ledger.description }}</td>

            <td class="text-right table-end-item">{{ (ledger.debit =='-')?'-':parseDigitForSlip(ledger.debit) }}</td>
            <td class="text-right table-end-item">{{ (ledger.credit =='-')?'-':parseDigitForSlip(ledger.credit) }}</td>
            <td class="text-right table-end-item">{{Math.abs(parseDigitForSlip(ledger.balance)) }} {{ ledger.sign }}.</td>
          </tr>
          <tr>
            <td></td>
            <td>Total</td>
            <td class="text-right table-end-item">{{parseDigitForSlip(ledgerDatas.debit_total)}}</td>
            <td class="text-right table-end-item">{{parseDigitForSlip(ledgerDatas.credit_total)}}</td>
            <td class="text-right table-end-item">{{Math.abs(parseDigitForSlip(ledgerDatas.total_balance))}} {{ledgerDatas.total_sign }}</td>
          </tr>
        </tbody>
        <tbody v-else-if="ledgerParams.error"><tr><td colspan="11" class="text-center">{{ledgerParams.error_message}}</td></tr></tbody>
        <tbody v-else-if="!ledgerParams.loading && ledgerDatas.data.length == 0">
          <tr>
            <td colspan="11" style="text-align:center;">No records found.</td>
          </tr>
        </tbody>
        <tbody v-else-if="ledgerParams.loading">
          <tr>
            <td colspan="11" style="text-align:center;">Loading.....</td>
          </tr>
        </tbody>
        
      </table>
    </div>
  </div>

  <!-- content -->
</template>
<script>
import { Search } from "../../../../mixins/search";
import { mapGetters } from "vuex";
import Services from "./Services/Services";

export default {
  mixins: [Search],
  computed: {
    ...mapGetters([
      "icons",
      "dataId",
      "dataLists",
      "dataLists3",
      "eventMessage",
      "modalId",
    ]),
    ...mapGetters("customers", ["ledgerDatas", "ledgerParams"]),
  },
  methods: {
    print() {
      window.print();
    },
    ledgerFilter() {
      this.ledgerParams.loading = true;
      Services.filterLedgerByDate(this.ledgerParams)
        .then((res) => {
          this.$store.commit("customers/setLedgerDatas", res.data.data);
          this.ledgerParams.loading = false;
          this.setNotification(res.data.success_message);
        })
        .catch((err) => {
          this.ledgerParams.error = true;
          this.ledgerParams.loading = false;
          let error = err.response.data.error_message;
          this.setNotification(error);
          if (
            !Object.prototype.hasOwnProperty.call(
              err,
              "response.data.error_message"
            )
          ) {
            if (err.response.data.error_message == "Access Denied.") {
              this.ledgerParams.error_message = "Access Denied !!!";
            } else {
              this.ledgerParams.error_message =
                "Error fetching data from the server.";
            }
          }
        });
    },
    setNotification(message) {
      this.$store.dispatch("enableMessage");
      this.$store.commit("setEventMessage", message);
    },
  },
};
</script>
<style scoped>
</style>