<template>
  <div class v-if="customerDatas">
    <div class="content-body content-body-profile pt-3 pt-xl-0">
      <div class="profile-sidebar">
        <div class="content-header pd-t-0">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/customer">Customer</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">Customer Detail</li>
              </ol>
            </nav>
          </div>
        </div>
        <!-- content-header -->
        <div class="profile-sidebar-header">
          <div class="avatar">
            <img
              :src="customerDatas.image?customerDatas.image:'/assets/img/logosmall.png'"
              class="rounded-circle"
              alt
            />
          </div>

          <h5>{{ customerDatas.name }}</h5>
          <div class="d-flex">
            <!-- <h6>#CSTMR{{ threeDigit(customerDatas.id) }} -</h6> -->
            <!-- <div class="tx-primary pd-l-5 tx-medium">Active</div> -->
          </div>
          <div class="d-flex align-self-stretch mt-1">
            <a href class="btn btn-sm btn-primary btn-uppercase flex-fill">Email</a>
            <button
              class="btn btn-white btn-sm btn-uppercase flex-fill mg-l-5"
              v-if="checkSinglePermission('edit_customer')"
              @click.prevent="edit('editCustomerProfile',customerDatas.id)"
            >Edit</button>
          </div>
        </div>

        <div class="profile-sidebar-body">
          <label class="content-label">Contact Details</label>
          <ul class="list-unstyled profile-info-list mg-b-0">
            <li>
              <i data-feather="briefcase"></i>
              <span class="tx-color-03">{{ customerDatas.address }}</span>
            </li>
            <li>
              <i data-feather="phone"></i>
              {{ customerDatas.phone }}
            </li>
            <li>
              <i data-feather="mail"></i>
              {{ customerDatas.email }}
            </li>
            <li>
              <i data-feather="mail"></i>
              <a :href="customerDatas.website" target="_blank">{{ customerDatas.website }}</a>
            </li>
          </ul>
          <span v-if="authData.general_setting.enable_vehicle_detail==1">
          <hr class="mg-y-10" />
          <label class="content-label">Vehicle Detail</label>
          <ul class="list-unstyled profile-info-list">
           <li>
              <span class="tx-color-03">
                Model Number:
                <span v-if="customerDatas.vehicle_detail">{{customerDatas.vehicle_detail.model_no}}</span>
              </span>
            </li>
            <li>
              <span class="tx-color-03">
                Vehicle Number:
                <span v-if="customerDatas.vehicle_detail">{{customerDatas.vehicle_detail.vehicle_no}}</span>
              </span>
            </li>
            <li>
              <span class="tx-color-03">
                Engine NUmber:
                <span v-if="customerDatas.vehicle_detail">{{customerDatas.vehicle_detail.engine_no}}</span>
              </span>
            </li>
            <li>
              <span class="tx-color-03">
                Chassis Number:
                <span v-if="customerDatas.vehicle_detail">{{customerDatas.vehicle_detail.chasis_no}}</span>
              </span>
            </li>
          </ul>
          </span>
          <hr class="mg-y-10" />
          <label class="content-label">Other Detail</label>
          <ul class="list-unstyled profile-info-list">
            <li>
              <span class="tx-color-03">PAN / VAT: {{ customerDatas.pan_vat_no }}</span>
            </li>
            <li>
              <span class="tx-color-03">Customer Created: {{ customerDatas.member_since }}</span>
            </li>
            <li>
              <span class="tx-color-03">
                Added By:
                <span v-if="customerDatas.user">{{customerDatas.user.name}}</span>
              </span>
            </li>
            <li>
              <span class="tx-color-03">
                Opening Balance: NPR
                {{ customerDatas.opening_due }}
              </span>
            </li>
            <li>
              <span class="tx-color-03">
                Closing Balance: NPR
                {{ customerDatas.closing_due }}
              </span>
            </li>
          </ul>
        </div>
        <!-- profile-sidebar-body -->
      </div>
      <!-- profile-sidebar -->
      <div class="profile-body">
        <div class="profile-body-header no-print">
          <div class="nav-wrapper">
            <ul class="nav nav-line" id="profileTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="overview-tab"
                  data-toggle="tab"
                  href="#overview"
                  role="tab"
                  aria-controls="overview"
                  aria-selected="true"
                >Overview</a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="projects-tab"
                  data-toggle="tab"
                  href="#bills"
                  role="tab"
                  aria-controls="projects"
                  aria-selected="false"
                  @click="sale()"
                >Sales</a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="people-tab"
                  data-toggle="tab"
                  href="#receipts"
                  role="tab"
                  aria-controls="people"
                  aria-selected="false"
                  @click="receipt()"
                >Receipt Voucehers</a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="payout-tab"
                  data-toggle="tab"
                  href="#payout"
                  role="tab"
                  aria-controls="payout"
                  aria-selected="false"
                  @click="payout()"
                >Payment Vouchers</a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="people-tab"
                  data-toggle="tab"
                  href="#ledger"
                  role="tab"
                  aria-controls="people"
                  aria-selected="false"
                  @click="ledger()"
                >Ledger</a>
              </li>
            </ul>
          </div>
          <!-- nav-wrapper -->
        </div>
        <!-- customer detail in print -->
        <div class="print-customer-detail">
          <div class="row">
            <div class="col-sm-4 col-lg-4 mg-t-sm-40 mg-t-10 mg-sm-t-0 order-2 order-sm-0">
              <label class="content-label mb-1">Customer Detail</label>
              <p class="mg-b-0 font-print-xxs">NAME : {{ customerDatas.name }}</p>
              <p class="mg-b-0 font-print-xxs">ADDRESS : {{ customerDatas.address }}</p>
              <p class="mg-b-0 font-print-xxs">PAN : {{ customerDatas.pan_vat_no }}</p>
            </div>
            <!-- col -->
            <div class="col-sm-4 col-lg-4 mg-t-30">
              <p class="text-center font-xl m-0">
                <span>Statement</span>
              </p>
              <hr class="print-sm-hr print-hr-margin" />
            </div>
            <div class="col-sm-4 col-lg-4 mg-t-sm-15 mg-t-30 order-1 order-sm-0">
              <p class="mg-b-0 text-sm-right font-print-xxs">Printed Date : Current Date</p>
            </div>
            <!-- col -->
          </div>
        </div>
        <!-- profile-body-header -->
        <div class="tab-content p-3 print-p-0">
          <div id="overview" class="tab-pane active show" v-if="dataLists8">
            <div class="stat-profile">
              <div class="stat-profile-body">
                <div class="row row-xs px-2">
                  <div class="col pd-r-7-5 pd-l-7-5">
                    <div class="card card-body pd-10 pd-md-15 bd-0 shadow-none bg-primary-light">
                      <h3
                        class="tx-normal tx-sans tx-spacing--4 tx-primary mg-b-5"
                      >{{ customerDatas.closing_due }}</h3>
                      <p class="font-s tx-color-02 mg-b-0">Due Amount</p>
                    </div>
                  </div>
                  <div class="col pd-r-7-5 pd-l-7-5">
                    <div class="card card-body pd-10 pd-md-15 bd-0 shadow-none bg-teal-light">
                      <h3 class="mg-b-5 tx-sans tx-spacing--2 tx-normal tx-teal">
                        <span v-if="dataLists8.sales">{{ dataLists8.sales.total_bills }}</span>
                      </h3>
                      <p class="font-s tx-color-03 mg-b-0">Total Sales</p>
                    </div>
                  </div>
                  <div class="col pd-r-7-5 pd-l-7-5">
                    <div class="card card-body pd-10 pd-md-15 bd-0 shadow-none bg-pink-light">
                      <h3 class="mg-b-5 tx-sans tx-spacing--2 tx-normal tx-pink">
                        <span v-if="dataLists8.sales">{{ dataLists8.sales.count }}</span>
                      </h3>
                      <p class="font-s tx-color-03 mg-b-0">Total Sales Bills</p>
                    </div>
                  </div>
                </div>
                <!-- row -->
              </div>
              <!-- stat-profile-body -->
            </div>
            <!-- stat-profile -->

            <label class="content-label content-label-lg mt-3 tx-color-01">Description</label>
            <p class="tx-color-03 m-0">{{ customerDatas.description }}</p>

            <hr class="mt-3" />
            <div class="row no-print">
              <div class="col-6">
                <label class="content-label content-label-lg pt-1 tx-color-01 mb-0">Contact Persons</label>
              </div>
              <div class="col-6 tx-right">
                <button
                  v-if="checkSinglePermission('create_contact_detail')"
                  class="btn btn-xs btn-primary"
                  @click="$store.dispatch('modalOpen', 'newContact')"
                >Add Contact Person</button>
              </div>
            </div>

            <div class="row pd-r-7-5 pd-l-7-5" v-if="checkSinglePermission('view_contact_detail')">
              <div
                class="col-md-4 mt-3 pd-r-7-5 pd-l-7-5"
                v-for="(contactDetails, index) in dataLists8.contact_details"
                :key="index"
              >
                <div class="card p-3">
                  <div class="contact-action-container">
                    <div
                      class="contant-action-icon mr-2"
                      v-if="checkSinglePermission('edit_contact_detail')"
                      title="Edit"
                      @click="editContactDetails('editContactDetails',contactDetails.id)"
                    >
                      <i class="fa fa-edit tx-primary"></i>
                    </div>
                    <div
                      class="contant-action-icon"
                      v-if="checkSinglePermission('delete_contact_detail')"
                      title="Delete"
                      @click="dropContactDetails(contactDetails.id,index)"
                    >
                      <i class="fa fa-trash tx-danger"></i>
                    </div>
                  </div>
                  <ul class="list-unstyled profile-info-list mb-0">
                    <li>
                      <span class="tx-color-03">Name: {{ contactDetails.name }}</span>
                    </li>
                    <li>
                      <span class="tx-color-03">Designation: {{ contactDetails.designation }}</span>
                    </li>
                    <li>
                      <span class="tx-color-03">Phone: {{ contactDetails.phone_no }}</span>
                    </li>
                    <li>
                      <span class="tx-color-03">Email:{{ contactDetails.email }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!-- Sales Panel Content Start-->
          <div class="tab-pane fade" id="bills" role="tabpanel" aria-labelledby="contact-tab5">
            <div class="bg-theam-secondary table-search-head no-print pt-0">
              <div class="row">
                <div class="filter-input col-md-9 d-block mr-0 pr-0">
                  <select name="size" @change="salesFilter()" v-model="params.sales_size" class="mr-2 mg-t-5">
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                    <option value="All">All</option>
                  </select>
                  <div class="mr-2 d-inline-block">
                    <label class="filter-lavel mb-0 mr-1 mg-t-5">From</label>
                    <input
                      type="date"
                      v-model="params.from_date"
                      placeholder="From"
                      class="filter-input mr-1 mg-t-5"
                      @change="salesFilter()"
                    />
                    <label class="filter-lavel mb-0 mr-1 mg-t-5">To</label>
                    <input
                      type="date"
                      v-model="params.to_date"
                      placeholder="To"
                      class="filter-input mr-1 mg-t-5"
                      @change="salesFilter()"
                    />
                  </div>
                </div>
                <div class="col-md-3 mg-t-5">
                  <div class="text-right">
                    <button class="btn-icons" @click="print()">
                      <i class="fas fa-print pr-1"></i>
                    </button>
                    <!-- <button class="btn-icons">
                      <i class="fas fa-file-pdf pr-1"></i>
                    </button>
                    <button class="btn-icons">
                      <i class="fas fa-file-excel pr-1"></i>
                    </button>
                    <button class="btn-icons">
                      <i class="fas fa-download"></i>
                    </button>-->
                  </div>
                </div>
              </div>
            </div>
            <div class="overflow-x-auto">
              <table class="table table2 table-hover" v-if="salesDatas">
                <thead>
                  <tr>
                    <th class="wd-5p table-start-item">S.N.</th>
                    <th class="wd-10p">Date</th>
                    <th class="wd-10p">Bill No</th>
                    <th :class="(authData.general_setting.vatable==1)?'wd-10p':'wd-15p'">Sale Amt.</th>
                    <th class="wd-10p" v-if="authData.general_setting.vatable==1">VAT</th>
                    <th :class="(authData.general_setting.vatable==1)?'wd-10p':'wd-15p'">Bill Amt.</th>
                    <th class="wd-10p">Paid Amt.</th>
                    <th class="wd-15p">Payment mode</th>
                    <th class="wd-10p">Due</th>
                    <th class="wd-10p text-center table-end-item no-print">Action</th>
                  </tr>
                </thead>
                <tbody v-if="!loading && salesDatas.length > 0">
                  <tr v-for="(sale, index) in salesDatas" :key="index">
                    <td class="table-start-item">{{ pageSerialNo+index }}.</td>
                    <td>{{ sale.date }}</td>
                    <td>
                      {{sale.prefix}}{{ sale.bill_no }}
                      <span
                        v-if="sale.status == 2"
                        class="tx-danger"
                      >-Canceled</span>
                    </td>
                    <td>{{ sale.sales_amount }}</td>
                    <td v-if="authData.general_setting.vatable==1">{{ sale.vat_amount }}</td>
                    <td>{{ sale.bill_amount }}</td>
                    <td>{{ sale.paid_amount }}</td>
                    <td>{{ sale.payment_methods.title }}</td>
                    <td>{{ sale.due_amount }}</td>
                    <td class="text-right table-end-item no-print">
                      <a
                        href="javascript:;"
                        class="mr-3"
                        v-if="checkSinglePermission('view_sale')"
                        title="View Slip"
                      >
                        <i class="fas fa-eye" @click="view('salesSlip', sale.id)"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else-if="!loading && salesDatas.length == 0">
                  <tr>
                    <td colspan="11" style="text-align:center;">No records found.</td>
                  </tr>
                </tbody>
                <tbody v-else-if="loading">
                  <tr>
                    <td colspan="11" style="text-align:center;">Loading.....</td>
                  </tr>
                </tbody>
                <tbody v-else-if="error">
                  <tr>
                    <td colspan="11" style="text-align:center;"> {{sale_error_message}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <Paginate
              :pageCount="totalPageCount"
              :containerClass="'pagination'"
              :clickHandler="clickCallback"
              v-if="totalPageCount > 1"
            />
          </div>
          <!-- Sales Panel Content End -->
          <!---individual content-->
          <ProfileReceipt />
          <!--individual-content-->
          <ProfileLedger />
          <!-- individualk content -->
          <PayOut />
        </div>
        <!-- tab-content -->
      </div>
      <!-- profile-body -->
    </div>
    <!-- content-body -->
    <EditCustomer @parent-event="getData"/>
    <ContactDetail />
    <SalesSlip @printSlip="printSlip"/>
  </div>
  <!-- content -->
</template>
<script>
import ContactDetail from "../Customer/AddContactPerson";
import Paginate from "vuejs-paginate";
import ProfileReceipt from "../Customer/ProfileReceipt";
import ProfileLedger from "../Customer/ProfileLedger";
import PayOut from "../Customer/PayOut";
import SalesSlip from "../Sales/SalesSlip";
import EditCustomer from "../Customer/EditCustomer";
import { mapGetters } from "vuex";
import Services from "./Services/Services";
import SalesServices from "../Sales/Services/Service";
// import _ from 'lodash';
export default {
  data() {
    return {
      params: {
        sales_size: "10",
        from_date: "",
        to_date: "",
        id: this.$route.params.id,
        offset: 0,
      },
      loading: true,
      error: false,
      sale_error_message: "",
      totalPageCount: 0,
      pageSerialNo: 1,
    };
  },
  components: {
    ContactDetail,
    ProfileReceipt,
    ProfileLedger,
    SalesSlip,
    EditCustomer,
    PayOut,
    Paginate,
  },
  computed: {
    ...mapGetters([
      "icons",
      "dataId",
      "dataLists",
      "dataLists2",
      "dataLists8",
      "dataLists3",
      "dataLists9",
      "eventMessage",
      "modalId",
      "authData",
      "today",
    ]),
    ...mapGetters("customers", [
      "salesDatas",
      "receiptDatas",
      "payOutDatas",
      "customerDatas",
      "receiptParams",
      "ledgerParams",
      "payOutParams",
      "customerAllLists",
    ]),
    ...mapGetters("sales", ["slipSettings", "dataSets"]),
  },
  methods: {
    print() {
      window.print();
    },
    printSlip(id) {
      //once the modal is opened popup print window
      window.print();

      window.onafterprint = this.afterPrint(id);
    },
    afterPrint(salesId) {
      SalesServices.Sales.updatePrintBillCount({ "sales-id": salesId })
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    clickCallback: function (page) {
      if (page == 1) {
        this.pageSerialNo = 1;
      } else {
        this.pageSerialNo = (page - 1) * this.params.size + 1;
      }
      this.params.offset = (page - 1) * this.params.size;
      this.sale();
    },
    view(modal, id) {
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalWithMode", { id: modal, mode: "read" });
    },
    dropContactDetails(id, index) {
      this.$swal({
        title: "Do you really want to delete ContactDetail ?",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Delete",
        cancelButtonText: "No",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return Services.deleteContactDetails(id)
            .then((res) => {
              if (
                res.data.success_message ==
                "Contact Person deleted successfully."
              ) {
                this.$delete(this.dataLists8.contact_details, index);
                this.setNotification(res.data.success_message);
              }
            })
            .catch((err) => {
              this.$swal.showValidationMessage(
                `Request failed: ${err.response.data.error_message}`
              );
              let error = err.response.error_message;
              this.setNotification(error);
              console.log(err);
            });
        },
      });
    },
    editContactDetails(modal, id) {
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalWithMode", { id: modal, mode: "edit" });
    },
    salesFilter() {
      this.loading = true;
      Services.getSalesFilteredDatas(this.params)
        .then((res) => {
          this.$store.commit("sales/setDataSets", res.data.data.data);
          this.$store.commit("customers/setSalesDatas", res.data.data.data);
          this.loading = false;
          this.totalPageCount = res.data.data.total_no_of_pages;
          this.setNotification(res.data.success_message);
        })
        .catch((err) => {
          if (
            !Object.prototype.hasOwnProperty.call(
              err,
              "response.data.error_message"
            )
          ) {
            if (err.response.data.error_message == "Access Denied.") {
              this.sale_error_message = "Access Denied !!!";
            } else {
              this.sale_error_message = "Error fetching data from the server.";
            }
          }
          this.error = true;
          this.loading=false;
        });
    },
    edit(modal, id) {
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalWithMode", { id: modal, mode: "edit" });
    },
    ledger() {
      let ledger_params = {
        from: "",
        to: "",
        id: this.$route.params.id,
        loading: true,
        error: false,
        error_message:'',
      };
      this.$store.commit("customers/setLedgerParams", ledger_params);
      this.ledgerParams.loading = true;
      Services.getLedgerDataForCustomer(this.$route.params.id)
        .then((res) => {
          this.$store.commit("customers/setLedgerDatas", res.data.data);
          this.ledgerParams.loading = false;
        })
        .catch((err) => {
          this.ledgerParams.error = true;
          if (
            !Object.prototype.hasOwnProperty.call(
              err,
              "response.data.error_message"
            )
          ) {
            if (err.response.data.error_message == "Access Denied.") {
              this.ledgerParams.error_message = "Access Denied !!!";
            } else {
              this.ledgerParams.error_message = "Error fetching data from the server.";
            }
          }
        });
    },
    receipt() {
      let receipt_params = {
        size: "10",
        from: "",
        to: "",
        id: this.$route.params.id,
        loading: true,
        error_message:'',
        error: false,
        offset: 0,
        totalPageCount: 0,
      };
      this.$store.commit("customers/setReceiptParams", receipt_params);
      this.receiptParams.loading = true;
      Services.getReceiptFilteredDatas(receipt_params)
        .then((res) => {
          this.$store.commit("customers/setReceiptDatas", res.data.data.data);
          receipt_params.totalPageCount = res.data.data.total_no_of_pages;
          this.receiptParams.loading = false;
        })
        .catch((err) => {
          this.receiptParams.error = true;
          this.receiptParams.loading=false;
          if (
            !Object.prototype.hasOwnProperty.call(
              err,
              "response.data.error_message"
            )
          ) {
            if (err.response.data.error_message == "Access Denied.") {
              this.receiptParams.error_message = "Access Denied !!!";
            } else {
              this.receiptParams.error_message = "Error fetching data from the server.";
            }
          }
        });
    },
    payout() {
      let payout_params = {
        size: "10",
        from: "",
        to: "",
        id: this.$route.params.id,
        loading: true,
        error: false,
        error_message:'',
        offset: 0,
        totalPageCount: 0,
      };
      this.$store.commit("customers/setPayOutParams", payout_params);
      this.payOutParams.loading = true;
      Services.getPayOutFilteredDatas(payout_params)
        .then((res) => {
          this.$store.commit("customers/setPayOutDatas", res.data.data.data);
          this.payOutParams.loading = false;
          this.payOutParams.totalPageCount = res.data.data.total_no_of_pages;
        })
        .catch((err) => {
          this.payOutParams.error = true;
          this.payOutParams.loading=false;
          if (
            !Object.prototype.hasOwnProperty.call(
              err,
              "response.data.error_message"
            )
          ) {
            if (err.response.data.error_message == "Access Denied.") {
              this.payOutParams.error_message = "Access Denied !!!";
            } else {
              this.payOutParams.error_message = "Error fetching data from the server.";
            }
          }
        });
    },
    sale() {
      this.loading = true;
      Services.getSalesFilteredDatas(this.params)
        .then((res) => {
          this.$store.commit("sales/setDataSets", res.data.data.data);
          this.$store.commit("customers/setSalesDatas", res.data.data.data);
          this.totalPageCount = res.data.data.total_no_of_pages;
          this.loading = false;
        })
        .catch((err) => {
          this.error = true;
          this.loading = false;
          if(
            !Object.prototype.hasOwnProperty.call(
              err,
              "response.data.error_message"
            )
          ){
            if (err.response.data.error_message == "Access Denied.") {
              this.sale_error_message = "Access Denied !!!";
            } else {
              this.sale_error_message = "Error fetching data from the server.";
            }
          }
        });
    },
    setNotification(message) {
      this.$store.dispatch("enableMessage");
      this.$store.commit("setEventMessage", message);
    },
    getData() {
      this.$store.commit(
            "customers/setCustomerDatas",
            []
          );
      Services.getCustomerData(this.$route.params.id)
        .then((res) => {
          this.$store.commit(
            "customers/setCustomerDatas",
            res.data.data.customer
          );
          this.$store.commit("setDataLists8", res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  beforeMount() {
    this.getData();
  },
  mounted() {
    SalesServices.ManageSales.getSlipPredefinedData()
      .then((response) => {
        this.$store.commit("sales/setSlipSettings", response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  watch: {
    eventMessage(value) {
      if (value.indexOf("created successfully") >= 0) {
        this.getData();
      }
    },
  },
};
</script>